import React from 'react';
import Header from './componentes/Header';
import styled, { createGlobalStyle } from 'styled-components';
import Manutencao from './componentes/SiteManutencao';
import WhatsappButton from './componentes/Whatsapp';
import CarouselEmpresas from './componentes/CarouselEmpresas'; // Use o seu CarouselEmpresas
import Footer from './componentes/Rodape';

// Estilos globais utilizando styled-components
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    background-color: white;
    box-sizing: border-box; /* Inclui padding e border no cálculo da largura e altura */
  }

  /* Estilos responsivos */
  @media (max-width: 1200px) {
    body {
      font-size: 15px;
      background-color: #f0f0f0; /* Cor de fundo suave para telas grandes, mas menores que desktop */
    }
  }

  @media (max-width: 992px) {
    body {
      font-size: 14px;
      background-color: lightgrey;
    }
  }

  @media (max-width: 768px) {
    body {
      font-size: 13px;
      background-color: lightblue;
    }
  }

  @media (max-width: 576px) {
    body {
      font-size: 12px;
      background-color: lightyellow;
    }
  }
`;

// Container principal da aplicação
const AppContainer = styled.div`
  width: 100vw;  /* Largura total da viewport */
  min-height: 100vh;  /* Altura mínima igual à altura total da viewport */
  display: flex;
  flex-direction: column;  /* Layout em coluna */
  align-items: center; /* Alinha o conteúdo ao centro horizontalmente */
  padding: 20px; /* Adiciona espaçamento interno */
  box-sizing: border-box; /* Inclui padding e border no cálculo de largura */
`;

// Componente principal da aplicação
function App() {
  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <Header />
        <Manutencao />
        <WhatsappButton />
        <CarouselEmpresas />
        <Footer />
      </AppContainer>
    </>
  );
}

export default App;
