import React from 'react';
import Logo from '../Logo';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center; /* Alinha o logo verticalmente no centro */
  padding: 10px 20px; /* Adiciona padding para espaçamento interno */

  /* Estilos para tablets (768px a 1024px) */
  @media (max-width: 1024px) {
    padding: 8px 16px;
  }

  /* Estilos para dispositivos móveis (até 767px) */
  @media (max-width: 767px) {
    padding: 6px 12px;
    font-size: 14px; /* Ajusta o tamanho da fonte, se houver texto */
  }

  /* Estilos para dispositivos móveis muito pequenos (até 576px) */
  @media (max-width: 576px) {
    padding: 4px 8px;
    font-size: 12px; /* Ajusta o tamanho da fonte, se houver texto */
  }
`;

function Header() {
  return (
    <HeaderContainer>
      <Logo />
    </HeaderContainer>
  );
}

export default Header;


