import React from 'react';

const Footer = () => {
  return (
    <footer style={{ 
      borderTop: '0px solid #ccc', // Adicionando uma linha superior
      textAlign: 'center', 
      position: 'fixed', 
      left: 0, 
      bottom: 0, 
      width: '100%',
      backgroundColor: '#f0f0f0',
      padding: '0px 0', // Adicionando espaço vertical
      
    }}>
      <p style={{ margin: 0 }}>Todos os direitos reservados Ágape Passagens e Turismo &copy; {new Date().getFullYear()}</p>
    </footer>
  );
};

export default Footer;