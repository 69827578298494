import React from 'react';
import { createRoot } from 'react-dom/client'; // Atualizado para React 18
import App from './App'; // Certifique-se de que o caminho está correto
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  li {
    list-style: none;    
  }
`;

const container = document.getElementById('root');
const root = createRoot(container); // Criar a raiz

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App /> {/* Componente principal da aplicação */}
  </React.StrictMode>
);
