import React from 'react';
import { FaWhatsapp, FaMapMarkerAlt } from 'react-icons/fa'; // Certifique-se de ter este pacote instalado
import styled from 'styled-components';

const WhatsappButton = () => {
  const phoneNumberLza = '556136212639'; // Substitua pelo seu número de telefone no formato internacional
  const messageLza = 'Olá, quero comprar passagens.'; // Mensagem pré-definida
  const whatsappLinkLza = `https://api.whatsapp.com/send?phone=${phoneNumberLza}&text=${encodeURIComponent(messageLza)}`;
  const locationLinkLza = 'https://maps.app.goo.gl/3zggL7aQoaKwBSFE8';
  const locationLinkVgo = 'https://maps.app.goo.gl/hVbPkaF6esbKSkrv9';

  return (
    <Container>
      <ButtonContainer>
        <StyledLink href={whatsappLinkLza} target="_blank" rel="noopener noreferrer">
          <FaWhatsapp size={40} style={{ marginRight: '8px' }} />
          WhatsApp Ágape Passagens e Turismo * Valparaíso e Luziânia *
        </StyledLink>
      </ButtonContainer>

      <TextContainer>
        <LocationBlock>
          <LocationTextBold>
            ÁGAPE PASSAGENS LUZIÂNIA - GO
          </LocationTextBold>
          <LocationText>
            Estamos localizados na:
            <MapLink href={locationLinkLza} target="_blank" rel="noopener noreferrer">
              <FaMapMarkerAlt size={10} style={{ marginRight: '6px' }} />
              Ver no Mapa
            </MapLink>
          </LocationText>
          <LocationText2>
            Avenida Alfredo Nasser Qd 85 Guichê 05 Terminal Rodoviário, Luziânia - GO
          </LocationText2>
          <LocationTextBold>
            Telefone:(61) 3621-2639
          </LocationTextBold>
        </LocationBlock>

        <LocationBlock>
          <LocationTextBold>
            ÁGAPE PASSAGENS VALPARAÍSO DE GOIÁS - GO
          </LocationTextBold>
          <LocationText>
            Estamos localizados na:
            <MapLink href={locationLinkVgo} target="_blank" rel="noopener noreferrer">
              <FaMapMarkerAlt size={10} style={{ marginRight: '6px' }} />
              Ver no Mapa
            </MapLink>
          </LocationText>
          <LocationText2>
            Rua A Qd 02 Lt 01 Loja: 01/02 Ed.Valcenter II Parque Rio Branco, Valparaíso de Goiás - GO
          </LocationText2>
          <LocationTextBold>
            Telefone:(61) 3627-1802
          </LocationTextBold>
        </LocationBlock>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  margin-bottom: 10px; /* Espaço entre o botão e os textos */
  display: flex
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: white;
  background-color: #25D366;
  border-radius: 15px;
  text-decoration: none;
  font-size: 20px;
  width: 100%; /* Botão ocupa toda a largura disponível */

  @media (max-width: 600px) {
    font-size: 18px; /* Ajuste o tamanho da fonte para telas menores */
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row; /* Textos das localizações lado a lado */
  justify-content: center;
  gap: 10px; /* Espaço entre os blocos de texto */

  @media (max-width: 800px) {
    flex-direction: column; /* Alinha textos em coluna no mobile */
    gap: 10px;
  }
`;

const LocationBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 300px;
  padding: 20px;
  border: 1px solid #000; /* Borda preta ao redor do bloco */
  border-radius: 10px; /* Borda arredondada */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra leve */
  background-color: #f9f9f9; /* Fundo claro */

  @media (max-width: 600px) {
    width: 100%; /* Blocos ocupam toda a largura no mobile */
    padding: 10px;
  }
`;

const LocationText = styled.p`
  color: red;
  font-size: 15px;
  margin: 0 0 5px 0;
  display: flex;
  align-items: center;
`;

const LocationTextBold = styled.p`
  color: black;
  font-size: 13px;
  font-weight: bold; /* Negrito */
  margin: 0 0 5px 0;
`;

const LocationText2 = styled.p`
  color: black;
  font-size: 14px;
  margin: 0 0 5px 0;
`;

const MapLink = styled.a`
  display: flex;
  align-items: center;
  color: blue;
  background-color: transparent;
  border: none;
  text-decoration: none;
  font-size: 14px;
  margin-left: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

export default WhatsappButton;
